import { Listbox, Transition } from '@headlessui/react'
import CheckIcon from '@heroicons/react/24/solid/CheckIcon'
import SelectorIcon from '@heroicons/react/24/solid/ChevronUpDownIcon'
import clsx from 'clsx'
import React from 'react'
import Label from './Label'
import Tippy from '@tippyjs/react'

type PropsIn = {
  label?: string,
  placeholder?: string,
  disabled?: boolean,
  value: string, // current value set
  values: {key: string, value: string, disabled?: boolean}[],
  error?: string,
  onChange: (value: string) => void,
  helpTooltip?: string,
  fullWidth?: boolean,
  removeCheckmark?: boolean,
  boldLabel?: boolean,
  size?: 'small'|'medium', // not implemented yet
  capitalizeValue?: boolean,
}
export default function (props: PropsIn) {
  return (
    <div className={clsx({'w-full': props.fullWidth})}>
    {props.label && (
      <Label label={props.label || ''} helpTooltip={props.helpTooltip} bold={props.boldLabel} />
    )}
    <Listbox disabled={props.disabled || false} value={props.value || ''} onChange={props.onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Tippy content="When choosing this oprator, please ensure that the values you input match exactly, including capitalization, as they are case sensitive." disabled={props.value !== 'eq'}>
              <Listbox.Button className={`${clsx([{'border-coral': !!props.error, 'bg-gray-50': props.disabled}])} relative w-full py-2 pl-3 pr-10 mt-1 text-left bg-white rounded-lg border border-solid border-gray-200 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm`}>
                <span className="block truncate h-6">
                  {props.values.find(v => v.key === props.value)?.value || props.placeholder || '-'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            </Tippy>
            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {props.values.map(({value, key, disabled}) => (
                  <Tippy content="When choosing this operator, please ensure that the values you input match exactly, including capitalization, as they are case sensitive." key={key} disabled={key !== 'eq'}>
                    <Listbox.Option
                      key={key}
                      className={({ active }) =>
                        `${
                          active
                            ? 'text-deepgray'
                            : 'text-medgray'
                        }
                        ${
                          disabled ? 'bg-gray-100 cursor-not-allowed hover:bg-gray-100' : 'hover:bg-verylightbrown'
                        } cursor-default select-none text-left relative py-3 leading-5 w-full`
                      }
                      value={key}
                      disabled={disabled}
                    >
                      {({ selected, active }) => (
                        <div>
                          <span
                            className={`${
                              selected ? ' font-semibold text-black' : 'font-normal'
                            } ${props.removeCheckmark ? 'pl-3' : 'pl-12'} block truncate`}
                          >
                            {value}
                          </span>
                          {(selected && !props.removeCheckmark) ? (
                            <span
                              className={`${
                                active ? 'text-amber-600' : 'text-amber-600'
                              }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  </Tippy>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    {props.error && (
      <div style={{minHeight: 20}} className="w-full text-left text-sm text-coral">{props.error || ''}</div>
    )}
    </div>
  )
}
